@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #e3e3e3;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply bg-background text-foreground;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.background-modal {
  background-image: radial-gradient(rgba(0, 0, 0, 0) 1px, white 1px);
  background-size: 4px 4px;
  backdrop-filter: brightness(100%) blur(3px);
}

.dark .background-modal {
  background-image: radial-gradient(rgba(0, 0, 0, 0) 1px, black 1px);
}

h1 {
  @apply text-2xl font-extrabold sm:text-3xl;
  letter-spacing: 0.005em;
}

h2 {
  @apply text-xl font-bold sm:text-2xl;
}

h3 {
  @apply text-xl font-medium;
}

a {
  @apply cursor-pointer font-medium hover:text-blue-600 no-underline;
}

.highlight {
  @apply hover:text-blue-600 underline;
  text-decoration-style: solid;
  text-decoration-color: var(--accent);
  text-decoration-thickness: 2px;
  text-underline-offset: 1px;
}

/* Remove spinner for html elements with input number */
/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mapboxgl-map > div[mapboxgl-children] {
  display: none;
}

.mapboxgl-canvas {
  border-radius: 100%;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-canvas-container {
  height: 300px;
}

.mapboxgl-marker {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  opacity: 1;
  transition: opacity 0.2s;
}
